import {
    DELETE_SUBSCRIPTION,
    GET_SUBSCRIPTION_TYPE,
    PAYMENT,
    PAYPAL,
    STRIPE,
    PACKAGE,
    SUBSCRIPTION,
    CLEAR_SUBSCRIPTION_ERROR,
    INSTA_MOJO_PAYMENT
} from "./actions";

export const GetSubscriptionTypesAction = () => ({
    type: GET_SUBSCRIPTION_TYPE
})

export const subscriptionAction = (payload) => ({
    type: SUBSCRIPTION,
    payload
})

export const paymentAction = (payload) => ({
    type: PAYMENT,
    payload
})

export const paypalAction = (payload) => ({
    type: PAYPAL,
    payload
})

export const stripeAction = (payload) => ({
    type: STRIPE,
    payload
})

export const instaMojoAction = (payload)=>{
    return {
        type : INSTA_MOJO_PAYMENT,
        payload
    }
}

export const packageAction = (payload) => ({
    type: PACKAGE,
    payload
})

export const deleteSubscriptionAction = payload => ({
    type: DELETE_SUBSCRIPTION,
    payload
})

export const clearSubscriptionError = () => ({
    type: CLEAR_SUBSCRIPTION_ERROR,
})