import {
  FETCH_ALL_NOTIFICATIONS,
  FETCH_ALL_UNREAD_NOTIFICATIONS,
  MARK_AS_READ,
  MARK_ALL_AS_READ,
} from './actions'

export const fetchAllNotificationsAction = () => ({
  type: FETCH_ALL_NOTIFICATIONS,
})

export const fetchAllUnreadNotificationsAction = () => ({
  type: FETCH_ALL_UNREAD_NOTIFICATIONS,
})

export const markAsReadNotificationAction = (payload) => ({
  type: MARK_AS_READ,
  payload,
})

export const markAsReadNotificationsAction = () => ({
  type: MARK_ALL_AS_READ,
})
